import React, { Component } from "react";
import "../style/header.scss";

const Header = () => {
  return (
    <>
      <h1 className="header">Emily Weilan Tao</h1>
    </>
  );
};

export default Header;
