import React, { Component } from "react";
import "../style/notfound.scss";

const NotFound = () => {
  return (
    <>
      <div className="notfoundPage">
        <p>oops...You've reached Not Found</p>
      </div>
    </>
  );
};

export default NotFound;
